import "./App.css";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import React, { lazy, Suspense } from 'react';
import ErrorComponent from "./components/errorComponent/errorComponent";
import { isUserLogin } from "./utils/Api";
import ShowSkeleton from "./pages/Skeleton/showSkeletion"; 
// Lazy loading components
const MainView        = lazy(() => import('./mainview'));
const BodyView        = lazy(() => import('./bodyview'));
const VideoDetailView = lazy(() => import('./pages/VideoPage/videoDetailView'));
const HomePage        = lazy(() => import('./pages/HomePage/homepage'));
const Search          = lazy(() => import('./pages/SearchPage/search'));
const ProfilePage     = lazy(() => import('./pages/UserProfilePage/profile'));
const FlashCards      = lazy(() => import('./pages/FlashCards/flashCards'));
const SmartLearn      = lazy(() => import('./pages/SmartLearnPage/smartLearn'));
const PlayVideo       = lazy(() => import('./pages/VideoPage/playVideo'));
const Subjects        = lazy(() => import('./pages/SubjectsPage/subjects'));
const ContentList     = lazy(() => import('./pages/FooterContent/contentList'));
const QuizDetailView  = lazy(() => import('./pages/QuizScreenLevels/quizDetailView'));
const Login           = lazy(() => import('./pages/LoginPage/login'));
const Troubleshoot    = lazy(() => import('./pages/FooterContent/troubleshoot'));
const FlashCardPlay   = lazy(() => import('./pages/FlashCards/flashCardPlay')); 
const QuizScreen      = lazy(() => import('./pages/QuizScreenLevels/quizPlay'));
const AboutUs         = lazy(() => import('./pages/FooterContent/aboutUs'));
const PlayFlashCard   = lazy(() => import('./pages/FlashCards/playFlashCard'));
const AiScreen        = lazy(() => import('./pages/AiScreens/aiScreen'));
const HelpAndSupport  = lazy(() => import('./pages/FooterContent/helpAndSupport')); 
const FlashCardsDetailView = lazy(() => import('./pages/FlashCards/flashCardsDetailView'));

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = isUserLogin(); // your logic to check if the user is authenticated
  return isAuthenticated ? <Suspense><Component {...rest} /></Suspense> : <Navigate to="/login" />;
};

const AfterLoginRoute = ({ element: Component, ...rest }) => {
  const isAuthenticated = isUserLogin(); // your logic to check if the user is authenticated
  return !isAuthenticated ?<Suspense> <Component {...rest} /></Suspense> : <Navigate to="/" />;
};

const router = createBrowserRouter([
  {
    path: "/ss",
    element: <ShowSkeleton />,
  },
  {
    path: "/impersonateLogin",
    element: (
      <Suspense>
        <Login />
      </Suspense>
    ),
  },
  {
    path: "/login",
    element: <AfterLoginRoute element={Login} />
  },
  {
    element: <ProtectedRoute element={BodyView} />,
    children: [
      {
        path: "/video/:id",
        element: <PlayVideo />,
      },
      {
        path: "/quizPlay/:quiz_id",
        element: <QuizScreen />,
      },
      {
        path: "/flashcard/:fc_id",
        element: <PlayFlashCard />,
      },
      {
        path: "/ai",
        element: <AiScreen />
      },
    ],
  },
  {
    element:<ProtectedRoute element={MainView} />,
    children: [
      {
        path: "/",
        element: <HomePage />
      },
      {
        path: "/home",
        element: <HomePage />
      },
      {
        path: "/videoDetailView/:id",
        element: <VideoDetailView />
      },
      {
        path: "/search",
        element: <Search />
      },
      {
        path: "/profile",
        element: <ProfilePage />
      },
      {
        path: "/smart-learn",
        element: <SmartLearn />
      },
      {
        path: "/see-all/:id",
        element: <FlashCards />
      },
      {
        path: "/subjects",
        element: <Subjects />
      },
      {
        path: "/subjects/:id",
        element: <Subjects />
      },
      {
        path: "/content-list/:id",
        element: <ContentList />
      },
      {
        path: "/troubleshoot",
        element: <Troubleshoot />
      },
      {
        path: "/helpandsupport",
        element: <HelpAndSupport />
      },
      {
        path: "/about-us",
        element: <AboutUs />
      },
      {
        path: "/quizDetailView/:id",
        element:  <QuizDetailView />
      },
      {
        path: "/flashCardDetailView/:id",
        element: <FlashCardsDetailView />
      },
      {
        path: "/flashCardplay/:key",
        element: <FlashCardPlay />
      }
    ],
    errorElement: <ErrorComponent />,
  },
   {
    path: "*",
    element: <Navigate to="/" />
  }
]);

const App = () => {
  return (
    <Suspense>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default App;
